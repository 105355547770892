<template>
    <div class="vvt-template-list">
        <GroupTemplateList v-if="hasVvtApp" />
    </div>
</template>

<script>
import activeAppsMixin from '@/mixins/activeAppsMixin';
import GroupTemplateList from '@/components/vvt/List/Templates';

export default {
    name: 'VvtTemplatesListView',
    components: {
        GroupTemplateList,
    },
    mixins: [activeAppsMixin],
};
</script>
