<template>
    <div>
        <portal to="appBarNavigationTitle">
            {{ $tc('companyGroupTemplates',2) }}
        </portal>
        <portal to="appBarNavigationAction">
            <div class="d-flex flex-row justify-end align-center">
                <div>
                    <BaseBackButton :route="{ name: 'Settings' }" />
                </div>
                <div>
                    <SearchRecord
                        v-if="selectedCategory"
                        v-model="searchTerm"
                        @input="searchRecords($event)"
                        />
                </div>
                <div class="ma-2">
                    <v-tooltip bottom color="primary" :disabled="!allCategories || !allCategories.length">
                        <template v-slot:activator="{ on }">
                            <div v-on="on" class="d-inline-block">
                                <v-btn
                                    :disabled="!allCategories || !allCategories.length"
                                    color="white"
                                    outlined
                                    fab
                                    small
                                    :elevation="2"
                                    light
                                    @click="$router.push({name: 'SettingsVvtGroupTemplatesSelectFromTemplate'})"
                                >
                                    <v-icon v-text="'add'" />
                                </v-btn>
                            </div>
                        </template>
                        <span
                            v-if="$hasRole('ROLE_TENANT_ADMIN')"
                            v-t="$t('vvtList.templates.create.tooltip')"
                        ></span>
                        <span
                            v-else
                            v-t="$t('notAuthorizedAction')"
                        ></span>
                    </v-tooltip>
                </div>
            </div>
        </portal>
        <v-container
            fluid
            class="py-0"
            :class="{ 'mt-4': $vuetify.breakpoint.md }"
        >
            <v-row no-gutters>
                <v-col cols="12" sm="4" md="3" lg="2" xl="2">
                    <TemplateCategories
                        :selectedCategory="selectedCategory"
                        @select-category="selectCategory($event)"
                    />
                </v-col>
                <template>
                    <v-col cols="12" sm="8" md="9" lg="10" xl="10">
                        <TemplateRecords
                            :selectedCategory="selectedCategory"
                            :selectedRecord="selectedRecord"
                            :templateRecords="templateRecords"
                            @select-record="selectRecord($event)"
                            @refetch-records="refetchRecords(false, $event)"
                            :page="page"
                            :initial-table-sortby="getLocalStorageTableSortby()"
                            :initial-table-sort-desc="getLocalStorageTableSortDesc()"
                            :limit="limitFetchRecords"
                            :totalRecordItems="totalRecordItems"
                        />
                    </v-col>
                </template>
            </v-row>
        </v-container>
        <v-navigation-drawer
            v-model="previewRecord"
            fixed
            temporary
            right
            :width="$rightSheetWidth()"
            disable-route-watcher
            @transitionend="previewRecordTransitionEnd($event)"
            >
            <RecordPreview
                v-if="selectedRecord"
                :toolbar-height="$vuetify.application.top"
                :selected-record="selectedRecord"
                :selected-filter="selectedFilter"
                @deleteRecordConfirmation="setDeleteConfirmation($event)"
                @close-record="closeRecord()"
                group-templates
            />
        </v-navigation-drawer>
        <BaseConfirmationDialog
            v-if="selectedRecord"
            v-model="deletionDialog"
            :title="$t('vvtList.actions.deleteTemplateConfirmationTitle')"
            :text="$t(deletionDialogText)"
            :confirmationText="$t('delete')"
            @confirm="deleteTemplateRecord()"
            :confirmationDisabled="!['archived', 'draft'].includes(selectedRecord.status.toLowerCase())"
            :maxWidth="$vuetify.breakpoint.smAndDown ? '95%' : '620px'"
            buttonColorConfirm="warning"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
const SearchRecord = () => import(/* webpackChunkName: "VvtSearch" */ '@/components/vvt/List/SearchRecord.vue');
import TemplateCategories from './TemplateCategories.vue';
import TemplateRecords from './TemplateRecords.vue';
const RecordPreview = () => import('./../RecordPreview/index.vue');
import titleMixin from '@/mixins/titleMixin';

const LEA_VVT_LIST_TABLE_PAGE = 'LEA_VVT_LIST_TABLE_PAGE';
const LEA_VVT_LIST_TABLE_SORTBY = 'LEA_VVT_LIST_TABLE_SORTBY';
const LEA_VVT_LIST_TABLE_SORTDESC = 'LEA_VVT_LIST_TABLE_SORTDESC';
const LIMIT_FETCH_RECORDS = 100;

export default {
    name: 'VvtGroupTemplateList',
    components: {
        TemplateCategories,
        TemplateRecords,
        RecordPreview,
        SearchRecord
    },
    mixins: [titleMixin],
    data() {
        return {
            page: this.getLocalStorageTablePage(),
            searchTerm: null,
            limitFetchRecords: LIMIT_FETCH_RECORDS,
            selectedFilterId: null,
            previewRecord: false,
            selectedRecord: null,
            selectedCategory: null,
            deletionDialog: false,
            deletionDialogText: null,
            allCategories: []
        };
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    title() {
        if (this.selectedCategory) {
            if (this.selectedCategory.id === 'all') {
                return this.currentTitle;
            }
            return `${this.selectedCategory.title} - ${this.currentTitle}`;
        }
        return this.currentTitle;
    },
    computed: {
        ...mapGetters({
            filterItems: 'filter/getAllFilter',
            categories: 'domain/getItems',
            getEventStoreDataByKey: 'eventStore/getDataByKey',
            getRecords: 'groupTemplates/getRecordsByCategory',
        }),
        aliasItem () {
            return this.$route && this.$route.query && this.$route.query.filterAlias ? [{
                        id: -1,
                        title: this.$route.query.filterAlias
                  }] : [];
        },
        selectedFilter() {
            if (!this.selectedFilterId) {
                return null;
            }
            if (this.selectedFilterId === 'all') {
                return 'all';
            }
            return (
                this.aliasItem.concat(this.filterItems).find(x => x.id === this.selectedFilterId) ||
                null
            );
        },
        smallButton () {
            return this.$vuetify.breakpoint.smAndDown || 
                (this.$vuetify.breakpoint.md && this.$vuetify.breakpoint.width < 1020) || 
                (this.$vuetify.breakpoint.lg && this.$vuetify.breakpoint.width < 1580) || 
                (this.$vuetify.breakpoint.xl && this.$vuetify.breakpoint.width < 1930)
        },
        currentTitle() {
            return this.$t('vvtList.title');
        },
        templateRecords() {
            return this.selectedCategory &&
                this.selectedCategory.id
                ? this.getRecords(this.selectedCategory.id)
                : [];
        },
        totalRecordItems () {
            if (this.selectedCategory && this.selectedCategory.id === 'all') {
                let recordsCount = 0;
                this.categories.forEach(x => {
                    recordsCount += parseInt(x.processingActivityCount);
                });
                return recordsCount;
            }
           return this.selectedCategory && Object.prototype.hasOwnProperty.call(this.selectedCategory, 'processingActivityCount') ? parseInt(this.selectedCategory.processingActivityCount) : 0; 
        }
    },
    methods: {
        ...mapActions({
            fetchSettings: 'companyGroupSettings/fetch',
            //fetchFilter: 'filter/fetch',
            fetchDomains: 'domain/fetch',
            fetchTemplateRecordsByCategory: 'groupTemplates/fetch',
            deleteActivity: 'companyProcessingActivities/delete',
            setEventStoreDataByKey: 'eventStore/set',
        }),
        previewRecordTransitionEnd (e) {
            if (e && e.propertyName === 'visibility' && !this.previewRecord) {
                this.closeRecord();
            }
        },
        setDeleteConfirmation (event) {
            this.deletionDialogText = event.deletionText;
            this.deletionDialog = true;
        },
        fetchAllCategories () {
            return new Promise(resolve => {
                this.$wait.start('fetch categories');
                this.fetchDomains({disableStoreUpdate: true}).then(result => {
                    this.$wait.end('fetch categories');
                    this.allCategories = result.data;
                    resolve(result.data);
                });
            });
        },
        fetchCategories () {
            return new Promise(resolve => {
                this.$wait.start('fetch categories');
                this.fetchDomains({countTemplates: true}).then(result => {
                    this.$wait.end('fetch categories');
                    resolve(result.data);
                });
            });
        },
        fetchTemplateRecords(categoryId) {
            this.$wait.start('fetch records');

            if (categoryId === 'all') {
                this.selectedCategory = { id: 'all' };
            } else {
                this.selectedCategory = this.categories.find(x => x.id === parseInt(categoryId));
            }

            if (
                this.selectedCategory &&
                this.selectedCategory.id
            ) {
                
                const options = {
                    searchTerm: this.searchTerm,
                    filterId: this.selectedFilterId,
                    page: this.getLocalStorageTablePage(),
                    sortBy: this.getLocalStorageTableSortby(),
                    sortDesc: this.getLocalStorageTableSortDesc(),
                    limit: this.limitFetchRecords
                };

                if (this.selectedCategory && this.selectedCategory.id !== 'all') {
                    options.domains = [this.selectedCategory.id];
                }
                this.fetchTemplateRecordsByCategory(options).then(() => {
                    this.$wait.end('fetch records');
                });
            } else {
                this.$wait.end('fetch records');
            }
        },
        selectCategory(objCategory) {
            this.closeRecord();
            if (!this.selectedCategory || objCategory.id !== this.selectedCategory.id) {
                const routeData = {
                    name: 'SettingsVvtGroupTemplatesList'
                };
                if (objCategory.id !== 'all') {
                    routeData.params = {
                      category: parseInt(objCategory.id)
                    }
                    this.selectedCategory = this.categories.find(x => x.id === parseInt(objCategory.id));
                } else {
                    this.selectedCategory = { id: 'all' };
                }
                this.$router.push(routeData);
            }
        },
        selectRecord(objRecord) {
            if (
                this.previewRecord &&
                this.selectedRecord &&
                this.selectedRecord.id === objRecord.id
            ) {
                this.closeRecord();
            } else {
                this.previewRecord = true;
                this.selectedRecord = objRecord;
            }
        },
        closeRecord() {
            this.previewRecord = false;
            this.selectedRecord = null;
        },
        deleteTemplateRecord() {
            const recordId = this.selectedRecord.id;
            this.closeRecord();
            this.deleteActivity({ id: recordId }).then(() => {
                this.refetchRecords();
            });
        },
        fetchRecordsAfterRouteChange (toCategory, fromCategory, forceFetch) {
            this.$setStorage(LEA_VVT_LIST_TABLE_PAGE, 1);
            if (!toCategory) {
                this.refetchRecords(true);
            } else if (toCategory && (forceFetch || parseInt(toCategory) !== parseInt(fromCategory))) {
                this.refetchRecords(false, null, toCategory, fromCategory);
            }
        },
        searchRecords () {
            this.fetchFilterAndRecords().then(data => {
                const currentCompanyHasResult = this.$route.params.company && data.find(x => x.id === this.$route.params.company);
                if (!currentCompanyHasResult) {
                    this.selectCategory({ id:'all' });
                }
            });
        },
        fetchCurrentFilterCategories (filter, force) {
            return new Promise(resolve => {
                this.selectedFilterId = filter ? filter : 'all';
                this.$wait.start('fetch categories');
                this.fetchCategories({ filterId: this.selectedFilterId, force: force, searchTerm: this.searchTerm }).then(result => {
                    this.$wait.end('fetch categories');
                    resolve(result);
                });
            });
        },
        fetchFilterAndRecords () {
            return new Promise(resolve => {
                const filterId = this.$route.params && this.$route.params.filter !== 'all' ? parseInt(this.$route.params.filter) : 'all';
                this.fetchCurrentFilterCategories(filterId, true).then((data) => {
                    this.fetchRecordsByRoute();
                    resolve(data);
                });
            });
        },
        fetchRecordsByRoute() {
            if (!this.$route.params.company) {
                this.refetchRecords(true);
            } else {
                this.refetchRecords();
            }
        },
        async refetchRecords (forceAll, options, forceDomainId, oldDomainId) {
            this.$wait.start('refetch vvt list');
            if (forceAll) {
                await this.fetchTemplateRecords('all');
            } else if (forceDomainId && oldDomainId && parseInt(forceDomainId) !== parseInt(oldDomainId)) {
                await this.fetchTemplateRecords(parseInt(forceDomainId));
            } else {
                await this.fetchTemplateRecords(this.$route.params.category ? this.$route.params.category : 'all');
            }
            this.$wait.end('refetch vvt list');
        },
        getLocalStorageTablePage () {
            let storagePage = localStorage.getItem(LEA_VVT_LIST_TABLE_PAGE);
            if (storagePage === null || storagePage === undefined) {
                this.page = 1;
                return 1;
            }
            
            this.page = parseInt(storagePage);
            return parseInt(storagePage);
        },
        getLocalStorageTableSortby () {
            let storageSortby = localStorage.getItem(LEA_VVT_LIST_TABLE_SORTBY);
            if (storageSortby === null || storageSortby === undefined) {
                return 'updatedAt';
            }
            return storageSortby;
        },
        getLocalStorageTableSortDesc () {
            let storageSortDesc = localStorage.getItem(LEA_VVT_LIST_TABLE_SORTDESC);
            if (storageSortDesc === null || storageSortDesc === undefined || storageSortDesc === 'true' || storageSortDesc === true) {
                return true;
            } else {
                return false;
            }
        },
        async initMount() {
            this.$wait.start('fetch init');
            await this.fetchSettings();
            await this.fetchCategories();
            await this.fetchAllCategories();
            //await this.fetchFilter().then(() => {
            //    this.$wait.end('fetch filter items');
            //});
            await this.fetchFilterAndRecords();
            this.$wait.end('fetch init');
        },
    },
    beforeCreate () {
        this.$wait.start('fetch categories');
        this.$wait.start('fetch records');
    },
    beforeDestroy () {
        this.$setStorage(LEA_VVT_LIST_TABLE_PAGE, 1);
        this.$removeClass(document.documentElement, 'disable-document-scrollbar');
    },
    mounted () {
        this.$addClass(document.documentElement, 'disable-document-scrollbar');
        this.initMount();
    },
    watch: {
        $route(to, from) {
            if (to.params.category === 'all') {
                // do not fetch same filtered companies again
                if (from.params.category !== 'all') {
                    this.fetchCategories().then(() => {
                        this.fetchRecordsAfterRouteChange(to.params.category, from.params.category);
                    });
                } else {
                    this.fetchRecordsAfterRouteChange(to.params.category, from.params.category);
                }
            } else {
                if (parseInt(to.params.category) !== parseInt(from.params.category)) {
                    this.fetchCategories().then(() => {
                      this.fetchRecordsAfterRouteChange(to.params.category, from.params.category);
                    });
                } else {
                    this.fetchRecordsAfterRouteChange(to.params.category, from.params.category);
                }
            }
        },
    }
};
</script>
