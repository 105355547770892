<template>
    <div>
        <div v-if="selectedCategory && selectedCategory.id">
            <TableView
                :recordItems="templateRecords"
                :selectedRecord="selectedRecord"
                :totalRecordItems="totalRecordItems"
                :page="page"
                :initialTableSortby="initialTableSortby"
                :initialTableSortDesc="initialTableSortDesc"
                :limit="limit"
                @select-record="$emit('select-record', $event)"
                @refetch-records="$emit('refetch-records', $event)"
                group-templates
            />
        </div>
    </div>
</template>

<script>
import TableView from './../TableView.vue';

export default {
    name: 'FilterResultCompanyRecords',
    components: {
        TableView
    },
    props: {
        selectedCategory: {
            type: Object,
            default: null
        },
        selectedRecord: {
            type: Object,
            default: null
        },
        filterId: {
            type: [Number, String],
            default: null
        },
        gridView: {
            type: Boolean,
            default: true
        },
        templateRecords: {
            type: Array,
            default: () => []
        },
        totalRecordItems: {
            type: Number,
            default: 0
        },
        page: {
            type: Number,
            default: 1
        },
        initialTableSortby: {
            type: String,
            default: null
        },
        initialTableSortDesc: {
            type: Boolean,
            default: false
        },
        limit: {
            type: Number,
            default: 10
        }
    },
    i18n: {
        messages: {
            en: require('@/locales/vvt/List/en.json'),
            de: require('@/locales/vvt/List/de.json'),
        },
    },
    data() {
        return {
            enableCheckbox: false
        };
    },
    computed: {
        companyScrollId() {
            return 'companyItem-' + this.selectedCategory.id;
        }
    }
};
</script>
